<template>
  <v-container fluid class="contact pa-0">
    <!-- =======================================================
                                Snackbar
    ============================================================-->
    <v-snackbar v-model="snackbar.active" centered vertical>
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar.active = false">OK</v-btn>
      </template>
    </v-snackbar>

    <v-row class="d-flex justify-center align-center">
      <v-col cols="12" md="8" lg="8" xl="8">
        <section class="associate">
          <div class="associate-content">
            <div>
              <div class="associate-block"></div>
              <div class="associate-title">Invista em <span>quem te defende</span></div>
              <span class="associate-button">SEJA SÓCIO</span>
            </div>
            <img src="../../../../assets/group-of-people.jpg" alt="group of people" class="associate-image" />
          </div>
        </section>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center align-center">
      <v-col cols="12" md="8" lg="8" xl="8">
        <h3 class="form-title">Preencha o formulário abaixo para se tornar sócio</h3>
        <p>Seus dados são sigilosos. Não compartilhamos ou vendemos para ninguém.</p>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center align-center">
      <v-col cols="12" md="8" lg="8" xl="8" class="pa-2">

        <v-card class="pa-10 mb-5">
          <v-row class="d-flex">
            <v-col cols="12" sm="12">
              <strong>Dados básicos</strong>
              <div>Alguns dados pessoais</div>
            </v-col>
          </v-row>
          <v-row class="d-flex">
            <v-col cols="12" sm="6">
              <v-text-field outlined :label="`${formFields.name.label} *`" v-model="formFields.name.value" :rules="formFields.name.rules" />
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field outlined :label="`${formFields.cpf.label} *`" v-model="formFields.cpf.value" :rules="formFields.cpf.rules" v-mask="'###.###.###-##'" />
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field outlined :label="`${formFields.rg.label} *`" v-model="formFields.rg.value" :rules="formFields.rg.rules" />
            </v-col>
          </v-row>
          <v-row class="d-flex">
            <v-col cols="12" sm="8">
              <v-text-field outlined :label="`${formFields.email.label} *`" v-model="formFields.email.value" :rules="formFields.email.rules" type="email"/>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field outlined :label="`${formFields.phone.label} *`" v-model="formFields.phone.value" :rules="formFields.phone.rules" type="tel" v-mask="'(##) #####-####'" />
            </v-col>
          </v-row>
          <v-row class="d-flex">
            <v-col cols="12" sm="3">
              <v-text-field outlined :label="`${formFields.date_birth.label} *`" v-model="formFields.date_birth.value" :rules="formFields.date_birth.rules" v-mask="'##/##/####'" />
            </v-col>
            <v-col cols="12" sm="3">
              <v-combobox clearable outlined :label="`${formFields.marital_status.label} *`" v-model="formFields.marital_status.value" :rules="formFields.marital_status.rules" :items="maritalStatusOptions" />
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field outlined :label="`${formFields.nationality.label} *`" v-model="formFields.nationality.value" :rules="formFields.nationality.rules" />
            </v-col>
          </v-row>
        </v-card>

        <v-card class="pa-10 mb-5">
          <v-row class="d-flex">
            <v-col cols="12" sm="12">
              <strong>Dados profissionais</strong>
              <div>Informações do banco onde você trabalha</div>
            </v-col>
          </v-row>
          <v-row class="d-flex">
            <v-col cols="12" sm="9">
              <v-combobox clearable outlined :label="`${formFields.bank_code.label} *`" v-model="formFields.bank_code.value" :rules="formFields.bank_code.rules" :items="bankOptions" />
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field outlined :label="`${formFields.bank_agency.label} *`" v-model="formFields.bank_agency.value" :rules="formFields.bank_agency.rules" v-mask="'####'" counter="4" />
            </v-col>
          </v-row>
          <v-row class="d-flex">
            <v-col cols="12" sm="3">
              <v-text-field outlined :label="`${formFields.bank_registration_number.label} *`" v-model="formFields.bank_registration_number.value" :rules="formFields.bank_registration_number.rules" />
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field outlined :label="`${formFields.bank_job_title.label} *`" v-model="formFields.bank_job_title.value" :rules="formFields.bank_job_title.rules" />
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field outlined :label="`${formFields.date_admission.label} *`" v-model="formFields.date_admission.value" :rules="formFields.date_admission.rules" v-mask="'##/##/####'" />
            </v-col>
          </v-row>
        </v-card>

        <v-card class="pa-10 mb-5">
          <v-row class="d-flex">
            <v-col cols="12" sm="12">
              <strong>Endereço</strong>
              <div>Onde você mora</div>
            </v-col>
          </v-row>
          <v-row class="d-flex">
            <v-col cols="12" sm="3">
              <v-text-field outlined :label="`${formFields.zipcode.label} *`" v-model="formFields.zipcode.value" :rules="formFields.zipcode.rules" @blur="zipcodeLookup" @input="zipcodeLookup" v-mask="'##.###-###'" hint="Buscar endereço pelo CEP" persistent-hint />
            </v-col>
            <v-col cols="12" sm="7">
              <v-text-field outlined :label="`${formFields.street.label} *`" v-model="formFields.street.value" :rules="formFields.street.rules" />
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field outlined :label="`${formFields.number.label} *`" v-model="formFields.number.value" :rules="formFields.number.rules" ref="addressNumber" />
            </v-col>
          </v-row>
          <v-row class="d-flex">
            <v-col cols="12" sm="3">
              <v-text-field outlined :label="`${formFields.complement.label}`" v-model="formFields.complement.value" :rules="formFields.complement.rules" />
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field outlined :label="`${formFields.district.label} *`" v-model="formFields.district.value" :rules="formFields.district.rules" />
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field outlined :label="`${formFields.city.label} *`" v-model="formFields.city.value" :rules="formFields.city.rules" />
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field outlined :label="`${formFields.state.label} *`" v-model="formFields.state.value" :rules="formFields.state.rules" />
            </v-col>
          </v-row>
        </v-card>

        <v-card class="pa-10 mb-5">
          <v-row class="d-flex">
            <v-col cols="12" sm="12">
              <strong>Dependentes</strong>
              <div>Informações básicas dos seus dependentes</div>
            </v-col>
          </v-row>

          <v-row class="d-flex" v-for="(field, index) in formDependentFields">
            <v-col cols="12" sm="4">
              <v-text-field outlined :label="`${field.name.label} *`" v-model="field.name.value" :rules="field.name.rules" />
            </v-col>
            <v-col cols="12" sm="3">
              <v-combobox clearable outlined :label="`${field.relationship.label} *`" v-model="field.relationship.value" :rules="field.relationship.rules" :items="relationshipOptions" />
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field outlined :label="`${field.date_birth.label} *`" v-model="field.date_birth.value" :rules="field.date_birth.rules" v-mask="'##/##/####'" />
            </v-col>
            <v-col cols="12" sm="2">
              <v-btn outlined class="py-6 red white--text" @click="removeDependent(index)">Remover</v-btn>
            </v-col>
          </v-row>

          <v-row class="d-flex">
            <v-col cols="12" sm="12">
              <v-btn outlined class="py-6 secondary white--text" @click="addDependent()">Adicionar dependente</v-btn>
            </v-col>
          </v-row>

        </v-card>

        <v-row class="d-flex">
          <v-col cols="12" sm="12">
            <p class="message-authorization">Autorizo o Banco referido a descontar mensalmente na folha de pagamento o valor da minha contribuição correspondente a 1,3% (um vírgula três por cento) do salário total, COM TETO MÁXIMO DE 55,00 reais. Considerando-se todos os proventos recebidos, para crédito do SINDICATO DOS EMPREGADOS EM ESTABELECIMENTO BANCÁRIOS DE UBERLÂNDIA-MG, como associado deste sindicato, sujeito às condições estabelecidas em seu ESTATUTO e REGULAMENTO.</p>
          </v-col>
        </v-row>

        <v-row class="d-flex">
          <v-col cols="12" sm="12">
            <v-btn outlined class="py-6 primary white--text" @click="create()" :disabled="formLoading">{{ formLoading === true ? 'Por favor, aguarde...' : 'Solicitar filiação' }}</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import associateService from "../../../../service/associateService";
import zipcodeService from "../../../../service/zipcodeService";
import associateHelper from '../../../../helpers/associate'

export default {
  name: "Associate",
  inject: ["theme"],
  metaInfo: {
    title: "SEEB | Seja sócio",
    meta: [{ name: "description", content: "Seja sócio do nosso sindicato. Invista em quem te defende." }],
  },
  data() {
    const validateCPF = (cpf) => {
      cpf = cpf.replace(/[^\d]/g, '');

      if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
          return false;
      }

      let sum = 0;
      for (let i = 0; i < 9; i++) {
          sum += parseInt(cpf.charAt(i)) * (10 - i);
      }

      let remainder = sum % 11;
      let digit1 = remainder < 2 ? 0 : 11 - remainder;

      if (parseInt(cpf.charAt(9)) !== digit1) {
          return false;
      }

      sum = 0;
      for (let i = 0; i < 10; i++) {
          sum += parseInt(cpf.charAt(i)) * (11 - i);
      }

      remainder = sum % 11;
      let digit2 = remainder < 2 ? 0 : 11 - remainder;

      if (parseInt(cpf.charAt(10)) !== digit2) {
          return false;
      }

      return true;
    }

    const rules = {
      required: value => !!value || 'Por favor, preencha este campo.',
      counter4: value => value.length === 4 || 'Por favor, informe 4 dígitos',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Por favor, informe um e-mail válido.'
      },
      phone: value => {
        const pattern = /^\(\d{2}\) \d{5}-\d{4}$/;
        return pattern.test(value) || "Por favor, informe um número de telefone válido";
      },
      date: value => {
        const pattern = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
        return pattern.test(value) || "Por favor, informe uma data válida (dd/mm/aaaa)";
      },
      cpf: value => {
        const isValid = validateCPF(value);

        if (!isValid) {
          return 'Por favor, informe um CPF válido';
        }

        return true;
      },
      zipcode: value => {
        const pattern = /^\d{2}\.\d{3}-\d{3}$/;
        return pattern.test(value) || "Por favor, informe um CEP válido";
      },
      numbersOnly: value => {
        const isANumber = !isNaN(value);
        return isANumber || "Por favor, informe apenas números";
      }
    }

    const maritalStatusOptions = [
      { text: 'Solteiro', value: 'solteiro' },
      { text: 'Casado', value: 'casado' },
      { text: 'Separado', value: 'separado' },
      { text: 'Divorciado', value: 'divorciado' },
      { text: 'Viúvo', value: 'viuvo' },
    ]

    const relationshipOptions = [
      { text: 'Pai', value: 'pai' },
      { text: 'Mãe', value: 'mae' },
      { text: 'Cônjuge', value: 'Cônjuge' },
      { text: 'Filho', value: 'filho' },
      { text: 'Filha', value: 'filha' },
    ]

    const bankOptions = associateHelper.bankList;

    const formDependentFields = [];

    return {
      formDependentFields: formDependentFields,
      bankOptions: bankOptions,
      maritalStatusOptions: maritalStatusOptions,
      relationshipOptions: relationshipOptions,
      rules: rules,
      formFields: {
        name: {
          label: 'Nome',
          value: "",
          rules: [rules.required],
        },
        cpf: {
          label: 'CPF',
          value: "",
          rules: [rules.required, rules.cpf],
        },
        rg: {
          label: 'RG',
          value: "",
          rules: [rules.required],
        },
        email: {
          label: 'E-mail',
          value: "",
          rules: [rules.required, rules.email],
        },
        phone: {
          label: 'Telefone',
          value: "",
          rules: [rules.required, rules.phone],
        },
        date_birth: {
          label: 'Data de nascimento',
          value: "",
          rules: [rules.required, rules.date],
          modify: 'date',
        },
        marital_status: {
          label: 'Estado civil',
          value: "",
          rules: [rules.required],
          modify: 'comboBox',
        },
        nationality: {
          label: 'Nacionalidade',
          value: "",
          rules: [rules.required],
        },
        bank_code: {
          label: 'Banco',
          value: "",
          rules: [rules.required],
          modify: 'comboBox',
        },
        bank_agency: {
          label: 'Nro. Agência',
          value: "",
          rules: [rules.required, rules.numbersOnly, rules.counter4],
        },
        bank_registration_number: {
          label: 'Nr. Matrícula',
          value: "",
          rules: [rules.required],
        },
        bank_job_title: {
          label: 'Cargo',
          value: "",
          rules: [rules.required],
        },
        date_admission: {
          label: 'Data de admissão',
          value: "",
          rules: [rules.required],
          modify: 'date',
        },
        zipcode: {
          label: 'CEP',
          value: "",
          rules: [rules.required, rules.zipcode],
        },
        street: {
          label: 'Rua',
          value: "",
          rules: [rules.required],
        },
        number: {
          label: 'Número',
          value: "",
          rules: [rules.required],
        },
        complement: {
          label: 'Complemento',
          value: "",
          rules: [],
        },
        district: {
          label: 'Bairro',
          value: "",
          rules: [rules.required],
        },
        city: {
          label: 'Cidade',
          value: "",
          rules: [rules.required],
        },
        state: {
          label: 'Estado',
          value: "",
          rules: [rules.required],
        },
      },
      openDialog: false,
      snackbar:{
        text:"",
        active:false
      },
      formLoading: false,
    };
  },
  methods: {
    resetForm() {
      this.formFields.name.value = "";
      this.formFields.cpf.value = "";
      this.formFields.rg.value = "";
      this.formFields.email.value = "";
      this.formFields.phone.value = "";
      this.formFields.date_birth.value = "";
      this.formFields.marital_status.value = "";
      this.formFields.nationality.value = "";
      this.formFields.bank_code.value = "";
      this.formFields.bank_agency.value = "";
      this.formFields.bank_registration_number.value = "";
      this.formFields.bank_job_title.value = "";
      this.formFields.date_admission.value = "";
      this.formFields.zipcode.value = "";
      this.formFields.street.value = "";
      this.formFields.number.value = "";
      this.formFields.complement.value = "";
      this.formFields.district.value = "";
      this.formFields.city.value = "";
      this.formFields.state.value = "";
      this.formDependentFields = []
    },
    addDependent() {
      this.formDependentFields.push({
        name: {
          label: 'Nome',
          value: "",
          rules: [this.rules.required],
        },
        relationship: {
          label: 'Relacionamento',
          value: "",
          rules: [this.rules.required],
          modify: 'comboBox',
        },
        date_birth: {
          label: 'Data de nascimento',
          value: "",
          rules: [this.rules.required, this.rules.date],
          modify: 'date',
        },
      });
    },
    removeDependent(index) {
      this.formDependentFields.splice(index, 1);
    },
    removeNonNumeric(inputString) {
      if (!inputString) {
        return null;
      }

      return inputString.replace(/\D/g, '');
    },
    getBankNameByCode(code) {
      const findBank = this.bankOptions.find(bank => bank.value === code);

      if (!findBank) {
        return null;
      }

      return findBank.text;
    },
    alertAddressNotFound() {
      this.snackbar.text = "Endereço não encontrado para o CEP informado!";
      this.snackbar.active = true;

      this.formFields.street.value = '';
      this.formFields.district.value = '';
      this.formFields.city.value = '';
      this.formFields.state.value = '';
    },
    async zipcodeLookup() {
      const zipcode = this.removeNonNumeric(this.formFields.zipcode.value);

      // ex.: 38.022-090
      if (zipcode && zipcode.length === 8) {
        try {
          const { data } = await zipcodeService.get(zipcode);

          if (data?.erro === true) {
            this.alertAddressNotFound()
          } else {
            this.formFields.street.value = data.logradouro;
            this.formFields.district.value = data.bairro;
            this.formFields.city.value = data.localidade;
            this.formFields.state.value = data.uf;
            this.$refs.addressNumber.focus();
          }
        } catch (error) {
          this.alertAddressNotFound()
        }
      }
    },
    create() {
      this.formLoading = true;
      const formValues = {}
      const formDependentValues = []
      const fieldListError = {}
      const fieldListDependentError = []

      Object
        .keys(this.formFields)
        .forEach(field => {
          const fieldLabel = this.formFields[field].label;
          const fieldValue = this.formFields[field].value;
          const fieldRules = this.formFields[field].rules;
          const fieldModify = this.formFields[field].modify;

          formValues[field] = fieldValue;

          if (fieldModify === 'date' && fieldValue) {
            const parts = fieldValue.split('/');
            formValues[field] = parts[2] + '-' + parts[1].padStart(2, '0') + '-' + parts[0].padStart(2, '0');
          }

          if (fieldModify === 'comboBox' && fieldValue?.value) {
            formValues[field] = fieldValue?.value;
          }

          fieldRules.forEach(rule => {
            if (rule(fieldValue) !== true) {
              fieldListError[fieldLabel] = fieldLabel;
            }
          });
        });

      for (let i = 0; i < this.formDependentFields.length; i++) {
        var item = this.formDependentFields[i];
        const formDependentValuesTemp = {}

        Object
          .keys(item)
          .forEach(field => {
            const fieldValue = item[field].value;
            const fieldRules = item[field].rules;
            const fieldModify = item[field].modify;

            formDependentValuesTemp[field] = fieldValue;

            if (fieldModify === 'date' && fieldValue) {
              const parts = fieldValue.split('/');
              formDependentValuesTemp[field] = parts[2] + '-' + parts[1].padStart(2, '0') + '-' + parts[0].padStart(2, '0');
            }

            if (fieldModify === 'comboBox' && fieldValue?.value) {
              formDependentValuesTemp[field] = fieldValue?.value;
            }

            fieldRules.forEach(rule => {
              if (rule(fieldValue) !== true) {
                if (!fieldListDependentError.includes(i + 1)) {
                  fieldListDependentError.push(i + 1)
                }
              }
            });
          });

          formDependentValues.push(formDependentValuesTemp);
      }

      if (Object.keys(fieldListError).length > 0) {
        const fieldListText = Object.keys(fieldListError).join(', ');

        this.snackbar.text = `Por favor, verifique novamente os campos [${fieldListText}] do formulário.`;
        this.snackbar.active = true;
        this.formLoading = false;

        return;
      }

      if (fieldListDependentError.length > 0) {
        const fieldListText = fieldListDependentError.join(', ');

        this.snackbar.text = `Por favor, verifique novamente os dependentes [${fieldListText}] no formulário.`;
        this.snackbar.active = true;
        this.formLoading = false;

        return;
      }

      formValues.bank_name = this.getBankNameByCode(formValues.bank_code);
      formValues.dependents = formDependentValues

      associateService
        .create(formValues)
        .then((response) => {
          if (response?.data?.status === 'error') {
            this.snackbar.text = response?.data?.message;
          } else {
            this.snackbar.text = "Obrigado! Sua solicitação foi enviada com sucesso, você vai receber uma resposta por e-mail, logo que ela for validada por nossa equipe!";
            this.resetForm();
          }

          this.snackbar.active = true;
        })
        .catch(error => {
          console.log('error saving associate form', error);
          this.snackbar.text = "Aconteceu algum erro inesperado no momento de enviar seus dados! Por favor, peço que entre em contato com nossa equipe. Os dados de contato estão no rodapé do site.";
          this.snackbar.active = true;
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
  },
  components: {
    Footer: () => import("@/layouts/web/commons/Footer.vue"),
  },
};
</script>

<style lang="scss" scoped>
.form-title {
  font-size: 22px;
  color: #334666;
}

.associate {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0px;
}

.associate-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px 100px;
  background: #334666;
  border-radius: 10px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1)
}

.associate-block {
  width: 30px;
  height: 2px;
  background: #ffffff;
}

.associate-button {
  color: #334666;
  padding: 5px 15px;
  background: #ffffff;
  border-radius: 5px;
}

.associate-title {
  color: #ffffff;
  font-size: 40px;
  margin-bottom: 15px;
}

.associate-title span {
  padding: 5px;
  color: #334666;
  background-color: #ffffff;
}

.associate-image {
  height: 250px;
}

.message-authorization {
  color: #db4545;
}
</style>
